.app-layout {
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas:
    'sidebar header'
    'sidebar main';
}

.sidebar {
  grid-area: sidebar;
}

.header {
  grid-area: header;
}

.main {
  grid-area: main;
}
